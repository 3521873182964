import { CircularProgress } from "@mui/material";
import BackButton from "components/BackButton";
import LineBreak from "components/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect, useState } from "react";
import {
  getHiveIsEditedSelector,
  getHiveIsEditingSelector,
  getHiveSelector,
  getPresignedUrlRespSelector,
  getIntroFileUrl,
  setHiveEdited,
} from "store/reducers/HiveDetails/hiveDetails";
import { setHiveTab } from "store/reducers/HiveDetails/hiveSettings";
import { isMemberViewSelector } from "store/reducers/Profile/profile";
import HiveTabs from "./HiveSettingsTab";
import {
  editHiveDetails,
  getHiveDetails,
  getIntroPresignedUrl,
} from "store/async-actions/Hive/hivePage";
import { toastSuccess } from "hooks/useToastify";
import ImageDropzone from "components/ImageDropzone";
import {
  getBannerFileUrlSelector,
  getBannerUrlChangedSelector,
  getLogoFileUrlSelector,
  getMobileLogoFileUrlSelector,
  getUrlChangedSelector,
  setBannerUploaded,
  setBannerUrlChanged,
  setUrlChanged,
} from "store/reducers/CreateChannel/createChannel";
import ReactPlayer from "react-player";
import FileDrop from "components/FileDrop";
import {
  getStillInEc2Selector,
  isUploadingSelector,
  populateFormData,
  setVideoFile,
  uploadFileSelector,
} from "store/reducers/Videos/videos";
import {
  readyToUpload,
  uploadToS3,
} from "store/async-actions/Videos/videosActions";

const HiveMedia = () => {
  const hiveDetails = useAppSelector(getHiveSelector);

  const uploadedFile = useAppSelector(uploadFileSelector);
  const uploadUrl = useAppSelector(getIntroFileUrl);

  const resourceUploadResp = useAppSelector(getPresignedUrlRespSelector);

  const bannerUrl = useAppSelector(getBannerFileUrlSelector);
  const fileUrl = useAppSelector(getLogoFileUrlSelector);
  const mobileUrl = useAppSelector(getMobileLogoFileUrlSelector);

  const urlChanged = useAppSelector(getUrlChangedSelector);
  const bannerUrlChanged = useAppSelector(getBannerUrlChangedSelector);

  const isEdited = useAppSelector(getHiveIsEditedSelector);
  const isLoading = useAppSelector(getHiveIsEditingSelector);

  const dispatch = useAppDispatch();

  const isMemberView = useAppSelector(isMemberViewSelector);
  const isUploading = useAppSelector(isUploadingSelector);

  const stillInEC2 = useAppSelector(getStillInEc2Selector);

  useEffect(() => {
    hiveDetails &&
      dispatch(
        readyToUpload({
          organizationUuid: hiveDetails?.communityUuid,
        })
      );
  }, [dispatch, hiveDetails]);

  useEffect(() => {
    if (isEdited) {
      toastSuccess("Hive Details Saved");

      dispatch(setHiveEdited(false));

      dispatch(
        getHiveDetails({
          communitySubDomain: localStorage.getItem("subDomain"),
          // organizationUuid: process.env.REACT_APP_ORG_UUID,
        })
      );
    }
  }, [isEdited, dispatch]);

  useEffect(() => {
    if (uploadedFile) {
      hiveDetails &&
        dispatch(
          getIntroPresignedUrl({
            type: "INTROVIDEO",
            communityId: hiveDetails.communityId,
          })
        );
    }
  }, [uploadedFile]);

  // useEffect(() => {
  //   if (!!resourceUploadResp && uploadedFile) {
  //     const formData = new FormData();
  //     populateFormData(formData, resourceUploadResp.preSigned, uploadedFile);
  //     dispatch(uploadToS3({ url: uploadToS3Url, formData: formData }));
  //   }
  // }, [resourceUploadResp]);

  useEffect(() => {
    dispatch(setHiveTab(1));
    dispatch(setBannerUrlChanged(false));
    dispatch(setUrlChanged(false));
    dispatch(setBannerUploaded(false));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    dispatch(
      editHiveDetails({
        communityName: "",
        communityTier: "",
        communityLogo: mobileUrl,
        communityBanner: bannerUrl,
        marketPlace: "",
        longDescription: "",
        communityUuid: hiveDetails?.communityUuid!,
        communitySubDomain: localStorage.getItem("subDomain")!,
        communityId: hiveDetails?.communityId!,
        communityBio: "",
        description: "",
        communityBioFlag: false,
        communityNameFlag: false,
        descriptionFlag: false,
        communityTierFlag: false,
        marketPlaceFlag: false,
        communityLogoFlag: urlChanged,
        communityBannerFlag: bannerUrlChanged,
        longDescriptionFlag: false,
        communityWebLogo: fileUrl,
        communityWebLogoFlag: urlChanged,
        introVideo: uploadUrl ? uploadUrl : "",
        introVideoFlag: !!uploadedFile,
        communitySubDomainFlag: false,
        showSuggested: true,
        showSuggestedFlag: false,
        chatSupportEnabled: false,
        chatSupportEnabledFlag: false,
        customDomain: "",
        customDomainFlag: false,
        introVideoThumbnail: "",
        introVideoThumbnailType: "",
        introVideoThumbnailFlag: false,
      })
    );
  };

  const [changeIntro, setChangeIntro] = useState(false);

  const handleChangeIntro = () => {
    setChangeIntro((prevState) => !prevState);
    dispatch(setVideoFile(null));
  };

  const removeHiveLogo = () => {
    dispatch(
      editHiveDetails({
        communityName: "",
        communityTier: "",
        communityLogo: "",
        communityBanner: "",
        marketPlace: "",
        longDescription: "",
        communityUuid: hiveDetails?.communityUuid!,
        communitySubDomain: localStorage.getItem("subDomain")!,
        communityId: hiveDetails?.communityId!,
        communityBio: "",
        description: "",
        communityBioFlag: false,
        communityNameFlag: false,
        descriptionFlag: false,
        communityTierFlag: false,
        marketPlaceFlag: false,
        communityLogoFlag: true,
        communityBannerFlag: false,
        longDescriptionFlag: false,
        communityWebLogo: "",
        communityWebLogoFlag: true,
        introVideo: "",
        introVideoFlag: false,
        communitySubDomainFlag: false,
        showSuggested: true,
        showSuggestedFlag: false,
        chatSupportEnabled: false,
        chatSupportEnabledFlag: false,
        customDomain: "",
        customDomainFlag: false,
        introVideoThumbnail: "",
        introVideoThumbnailType: "",
        introVideoThumbnailFlag: false,
      })
    );
  };

  const removeHiveBanner = () => {
    dispatch(
      editHiveDetails({
        communityName: "",
        communityTier: "",
        communityLogo: "",
        communityBanner: "",
        marketPlace: "",
        longDescription: "",
        communityUuid: hiveDetails?.communityUuid!,
        communitySubDomain: localStorage.getItem("subDomain")!,
        communityId: hiveDetails?.communityId!,
        communityBio: "",
        description: "",
        communityBioFlag: false,
        communityNameFlag: false,
        descriptionFlag: false,
        communityTierFlag: false,
        marketPlaceFlag: false,
        communityLogoFlag: false,
        communityBannerFlag: true,
        longDescriptionFlag: false,
        communityWebLogo: "",
        communityWebLogoFlag: false,
        introVideo: "",
        introVideoFlag: false,
        communitySubDomainFlag: false,
        showSuggested: true,
        showSuggestedFlag: false,
        chatSupportEnabled: false,
        chatSupportEnabledFlag: false,
        customDomain: "",
        customDomainFlag: false,
        introVideoThumbnail: "",
        introVideoThumbnailType: "",
        introVideoThumbnailFlag: false,
      })
    );
  };

  return (
    <div className="ce_about_wrapper">
      <div className="back_button_spacing">
        <BackButton to="/home" />
      </div>
      <HiveTabs />
      <div className="about_container">
        <LineBreak />
        <div className="title_and_limit">
          <h3>Hive Banner</h3>
          <p onClick={removeHiveBanner} className="link text-sm">
            Remove Image
          </p>
        </div>
        {hiveDetails && (
          <ImageDropzone
            bannerUrl={hiveDetails?.communityBanner}
            file="banner"
          />
        )}
        <div className="title_and_limit">
          <h3>Hive Logo</h3>
          <p onClick={removeHiveLogo} className="link text-sm">
            Remove Image
          </p>
        </div>
        <ImageDropzone urlSent={hiveDetails?.communityWebLogo!} file="logo" />
        {!stillInEC2 && (
          <>
            <div className="intro_video_edit_header">
              <h3>Introduction Video</h3>
              {!!hiveDetails?.introVideo && (
                <p onClick={handleChangeIntro} className="link text-sm">
                  {`${changeIntro ? "Cancel" : "Change Video"}`}
                </p>
              )}
            </div>
            {!!!hiveDetails?.introVideo ? (
              <>
                {uploadedFile ? (
                  <div className="intro_video_edit_wrapper">
                    <ReactPlayer
                      width="270px"
                      height="480px"
                      url={URL.createObjectURL(uploadedFile)}
                      controls
                      style={{
                        overflow: "hidden",
                        background: "black",
                      }}
                    />
                  </div>
                ) : (
                  <FileDrop />
                )}
              </>
            ) : (
              <>
                {changeIntro ? (
                  <>
                    {uploadedFile ? (
                      <div className="intro_video_edit_wrapper">
                        <ReactPlayer
                          width="270px"
                          height="480px"
                          url={URL.createObjectURL(uploadedFile)}
                          controls
                          style={{
                            overflow: "hidden",
                            background: "black",
                          }}
                        />
                      </div>
                    ) : (
                      <FileDrop />
                    )}
                  </>
                ) : (
                  <div className="intro_video_edit_wrapper">
                    <ReactPlayer
                      width="270px"
                      height="480px"
                      url={hiveDetails?.introVideo}
                      controls
                      style={{
                        overflow: "hidden",
                        background: "black",
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
        <>
          {isLoading || isUploading ? (
            <div className="nextBtn primaryBtn">
              <CircularProgress size={20} color="inherit" />
            </div>
          ) : (
            <div onClick={handleSave} className="nextBtn primaryBtn">
              Save
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default HiveMedia;
